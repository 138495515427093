// The core place for overriding bootstrap scss variables

// Darkly 4.5.3
// Bootswatch

//
// Color system
//

$white: #fff;
$gray-100: #f8f9fa;
$gray-200: #ebebeb;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #9b9a9a;
$gray-700: #393e41;
$gray-800: #303030;
$gray-900: #222;
$black: #000;

$blue: #42566a;
$indigo: #6610f2;
$purple: #6f42c1;
$pink: #e83e8c;
$red: #bf4044;
$orange: #fd7e14;
$yellow: #f39c12;
$green: #00bc8c;
$lightgreen: lightgreen;
$darkgreen: #2f4a36;
$teal: #20c997;
$cyan: #3498db;
$brown: #b7410e;
$beige: beige;

$colors: (
  "blue": $blue,
  "indigo": $indigo,
  "purple": $purple,
  "pink": $pink,
  "red": $red,
  "orange": $orange,
  "yellow": $yellow,
  "green": $green,
  "teal": $teal,
  "cyan": $cyan,
  "black": $black,
  "white": $white,
  "brown": $brown,
  "lightgreen": $lightgreen,
  "darkgreen": $darkgreen,
  "beige": $beige,
  "gray": $gray-600,
  "gray-dark": $gray-800,
);

$grays: (
  "100": $gray-100,
  "200": $gray-200,
  "300": $gray-300,
  "400": $gray-400,
  "500": $gray-500,
  "600": $gray-600,
  "700": $gray-700,
  "800": $gray-800,
  "900": $gray-900,
  "brown": $brown,
  "lightgreen": $lightgreen,
  "darkgreen": $darkgreen,
);

$primary: $blue;
$secondary: $gray-700;
$success: $green;
$info: $cyan;
$warning: $yellow;
$danger: $red;
$light: $gray-500;
$dark: $gray-800;

$yiq-contrasted-threshold: 175;

// Body
$tooltip-color: $white;
$tooltip-bg: $white;
$body-bg: $gray-900;
$body-color: $white;

// Links
$link-color: $white;
$link-hover-color: #859dff;

// Fonts

// stylelint-disable-next-line value-keyword-case
$font-family-sans-serif:
  Lato,
  -apple-system,
  BlinkMacSystemFont,
  "Segoe UI",
  Roboto,
  "Helvetica Neue",
  Arial,
  sans-serif,
  "Apple Color Emoji",
  "Segoe UI Emoji",
  "Segoe UI Symbol";
$font-size-base: 0.9375rem;
$font-size-sm: $font-size-base * 0.88;
$h1-font-size: 3rem;
$h2-font-size: 2.5rem;
$h3-font-size: 2rem;
$text-muted: $gray-600;

// Tables

$table-accent-bg: $gray-800;
$table-border-color: $gray-700;
$table-cell-padding-y: 0.75rem;
$table-cell-padding-x: 0.75rem;
$table-cell-padding-y-sm: 0.75rem;
$table-cell-padding-x-sm: 0.75rem;

// Forms

$input-border-color: $body-bg;
$input-group-addon-color: $gray-500;
$input-group-addon-bg: $gray-700;
$custom-file-color: $gray-500;
$custom-file-border-color: $body-bg;

// Dropdowns

$dropdown-bg: $gray-900;
$dropdown-border-color: $gray-700;
$dropdown-divider-bg: $gray-700;
$dropdown-link-color: $white;
$dropdown-link-hover-color: $white;
$dropdown-link-hover-bg: $primary;

// Navs

$nav-link-padding-x: 2rem;
$nav-link-disabled-color: $gray-500;
$nav-tabs-border-color: $gray-700;
$nav-tabs-link-hover-border-color: $nav-tabs-border-color $nav-tabs-border-color
  transparent;
$nav-tabs-link-active-color: $white;
$nav-tabs-link-active-border-color: $nav-tabs-border-color
  $nav-tabs-border-color transparent;

// Navbar

$navbar-padding-y: 1rem;
$navbar-dark-color: rgba($white, 0.6);
$navbar-dark-hover-color: $white;
$navbar-light-color: rgba($gray-900, 0.7);
$navbar-light-hover-color: $gray-900;
$navbar-light-active-color: $gray-900;
$navbar-light-toggler-border-color: rgba($gray-900, 0.1);

// Pagination

$pagination-color: $white;
$pagination-bg: $success;
$pagination-border-width: 0;
$pagination-border-color: transparent;
$pagination-hover-color: $white;
$pagination-hover-bg: lighten($success, 10%);
$pagination-hover-border-color: transparent;
$pagination-active-bg: $pagination-hover-bg;
$pagination-active-border-color: transparent;
$pagination-disabled-color: $white;
$pagination-disabled-bg: darken($success, 15%);
$pagination-disabled-border-color: transparent;

// Jumbotron

$jumbotron-bg: $gray-800;

// Cards

$card-bg: #393e41 !important;
$card-cap-bg: $card-bg;
$card-color: #fff !important;

// Popovers

$popover-bg: $gray-800;
$popover-header-bg: $gray-700;

// Toasts

$toast-background-color: $gray-700;
$toast-header-background-color: $gray-800;

// Modals

$modal-content-bg: $gray-800;
$modal-content-border-color: $gray-700;
$modal-header-border-color: $gray-700;

// Progress bars

$progress-bg: $gray-700;

// List group

$list-group-bg: $gray-800;
$list-group-border-color: $gray-700;
$list-group-hover-bg: $gray-700;

// Breadcrumbs

$breadcrumb-bg: $gray-700;

// Close

$close-color: $white;
$close-text-shadow: none;

// Code

$pre-color: inherit;

// Accordion
.accordion {
  --bs-accordion-active-bg: var(--bs-gray-900) !important;
}

button.btn.btn-link:hover {
  text-decoration: none !important;
}

button.btn.btn-link:focus {
  text-decoration: none !important;
  box-shadow: none !important;
}

//Explicitly expand buttons in Accordion components to 100% width
.card button.btn.btn-link {
  width: 100%;
  text-align: left;
}

//Style Forms
.form-control {
  height: 2.75rem;
  font-size: 1.2rem;
}

input.form-control {
  background-color: transparent !important;
  color: white !important;
  border: solid white 1px !important;
}

input.form-control:focus {
  box-shadow: 3px blue;
  color: white;
}

select.form-select {
  color: white !important;
  background-color: #4c4c4c !important;
}

select:focus {
  background-color: #4c4c4c;
  box-shadow: 3px blue;
  color: white;
}

option {
  background-color: #1b1f22;
  color: white;
  font-size: 18px;
}

.custom-control.custom-checkbox {
  transform: scale(1.2);
  transform-origin: 0;
}

// Used by Angular for Screen-reader only text. Hiding it normally appears to be broken (?)
// https://mdbootstrap.com/docs/angular/utilities/visually-hidden/#example1
.visually-hidden {
  display: none;
}

.vh-50 {
  height: 50vh;
}

.tooltip-inner {
  --bs-tooltip-bg: var(--bs-info-bg-subtle);
  --bs-tooltip-color: var(--bs-emphasis-color);
}
